//
// Main init file of global bootstrap and theme functions, mixins, variables and config
//


// Functions
@import "base/functions";

// Mixins
@import "base/mixins";
@import "components/mixins";
@import "vendors/plugins/mixins";

// Bootstrap mixins
@import "~bootstrap/scss/mixins";

// Global variables
@import "components/variables.demo";
@import "components/variables.bootstrap";
@import "components/variables.custom";

// 3rd-Party plugins variables
@import "vendors/plugins/variables";

// Theme layout variables
@import "layout/variables";

::-webkit-scrollbar {
    width: 8px;
    height: 8px;
}

::-webkit-scrollbar-track {
    background-color:#d3d3d3ce;
    -webkit-box-shadow: inset 0 0 0 rgba(206, 206, 206, 0.089) !important;
    border-radius: 10px;
}

::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color:#8b8a8a;
    -webkit-box-shadow: inset 0 0 0 rgba(199, 199, 199, 0.288);
}

.table.table-head-custom thead tr, .table.table-head-custom thead th {
    color: #7e8299 !important;
}